import React from 'react';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import ContraOferta from './ContraOferta';
import Compartir from './Compartir';

import { guardarContraoferta, aceptarAsignacion } from '../../../../../api/contraoferta';
import { hacerPregunta, responderPregunta } from '../../../../../api/preguntaMarket';
import { getCurrencies } from '../../../../../api/currency';
import { getVehicleList } from '../../../../../api/vehicle';
import { getDrivers } from '../../../../../api/driver';
import { getTrailerList } from '../../../../../api/trailer';
import useFetch from '../../../../../hooks/useFetch';

export const useActionHook = (publicacion, loadOrder, info, setLoading) => {
  const [t] = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [vehicles] = useFetch(() => getVehicleList());
  const [drivers] = useFetch(() => getDrivers());
  const [trailers] = useFetch(() => getTrailerList());
  const [monedas] = useFetch(getCurrencies);

  const handleClick = (key) => {
    closeSnackbar(key);
  };
  const ListVehicles = vehicles?.content?.map((item) => {
    return { id: item?.id, label: item?.dominio };
  });
  const ListDrivers = drivers?.content?.map((item) => {
    return { id: item?.id, label: `${item?.nombre} ${item?.apellido}` };
  });
  const ListTrailer = trailers?.content?.map((item) => {
    return { id: item?.id, label: item?.dominio };
  });

  const navigate = useNavigate();

  const actionContactar = () => {
    const currentURL = window.location.pathname;
    if (currentURL.startsWith('/marketplace')) navigate(`/marketplace/contaco/${info}`);
    else navigate(`/flota/contacto/${info}`);
  };

  async function getCurrencyId(symbol) {
    try {
      const { data } = await getCurrencies();
      const currencyFound = data.find((c) => c.simbolo === symbol);
      if (currencyFound) return currencyFound.id;
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const actionAceptarAsignacion = async (order) => {
    const publicacionAAceptar = order || publicacion;
    const { data } = await aceptarAsignacion(publicacionAAceptar.id);
    if (data) {
      enqueueSnackbar(t('Marketplace.Actions.Upload accepted successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
      navigate('/flota/os');
    }
  };
  const actionAceptar = async (order, cbOnError) => {
    const publicacionAAceptar = order || publicacion;
    try {
      const idMoneda = await getCurrencyId(publicacionAAceptar.moneda);
      if (!idMoneda) throw new Error();
      await guardarContraoferta({
        idMoneda,
        idCarga: publicacionAAceptar.id,
        isFavorito: true,
        valor: publicacionAAceptar.valor,
      });
      enqueueSnackbar(t('Marketplace.Actions.Upload accepted successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
      navigate('/flota/os');
    } catch (e) {
      if (e?.response?.data?.error && cbOnError) {
        cbOnError('Informacion', e.response.data.error);
        return;
      }
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
    }
  };

  const actionPreguntar = async (order) => {
    const publicacionAPreguntar = order || publicacion;
    const nuevaPregunta = document.getElementById('pregunta').value;
    try {
      await hacerPregunta({ pregunta: nuevaPregunta }, publicacionAPreguntar.id);
      enqueueSnackbar(t('Marketplace.Actions.OnSuccessQuestion'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
      await loadOrder();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
        onClick: (key) => handleClick(key),
      });
    }
  };

  const actionResponder = (order) => {
    const publicacionAResponder = order || publicacion;
    const preguntas = publicacionAResponder.preguntas.filter(
      (unaPregunta) => !unaPregunta.respuesta,
    );
    preguntas.forEach((unaPregunta) => {
      const pregunta = { ...unaPregunta };
      pregunta.respuesta = document.getElementById(`respuesta ${unaPregunta.id}`).value;
      responderPregunta(pregunta, publicacionAResponder.id);
    });
  };

  const actionVerOferente = () => {
    navigate('home');
  };

  const actionContraoferta = async (order) => {};

  const aceptarAsignacionBottom = {
    name: 'AceptarAsignacion',
    header: t('Button.Confirm'),
    title: t('Button.Confirm'),
    action: actionAceptarAsignacion,
  };

  const aceptar = {
    name: 'Aceptar',
    header: t('Marketplace.Actions.AcceptOfferHeader'),
    title: t('Button.Request'),
    action: actionAceptar,
  };
  const contactar = {
    name: 'Contactar',
    header: t('Button.Contact'),
    title: t('Button.Contact'),
    action: actionContactar,
  };
  const preguntar = {
    name: 'Preguntar',
    header: t('Marketplace.Actions.AskHeader'),
    body: <TextField id="pregunta" multiline maxRows={4} fullWidth />,
    title: t('Button.Ask'),
    action: actionPreguntar,
  };
  const contraoferta = {
    name: 'Ofertar',
    title: t('Button.Offer'),
    header: t('Marketplace.Actions.CountertofferHeader'),
    body: (
      <ContraOferta
        publicacion={publicacion || []}
        vehicles={ListVehicles || []}
        drivers={ListDrivers || []}
        trailers={ListTrailer || []}
        setLoading={setLoading || []}
        monedas={monedas || []}
      />
    ),
    action: actionContraoferta,
  };
  const compartir = {
    name: 'Compartir',
    title: t('Button.Share'),
    header: t('Marketplace.Actions.LoadLink'),
    body: <Compartir />,
  };
  const verOferente = {
    name: 'Ver oferente',
    title: t('Button.ViewBidder'),
    header: t('Marketplace.Actions.LoadLink'),
    action: actionVerOferente,
  };
  const verRespuesta = {
    name: 'Ver respuestas',
    title: t('Button.ViewAnswers'),
    header: t('Marketplace.Actions.LoadLink'),
    body: <Compartir />,
    action: actionResponder,
  };

  return {
    aceptar,
    aceptarAsignacionBottom,
    contactar,
    preguntar,
    contraoferta,
    compartir,
    /*  responder, */
    verRespuesta,
    verOferente,
  };
};
